<script lang="ts">
export default {
  name: "DefaultLayout",
};
</script>

<script lang="ts" setup>
import {useGtagCookieConsent} from "~/composables/useMaasCookieConsent";

const { loadNavigationElements } = useNavigation();
const { data } = await useAsyncData("data-headernavigation", async () => {
  const { value, addToCache } = await useDataCache('data-headernavigation')

  if (value) {
    return value
  }

  const response = await loadNavigationElements({ depth: 2 });
  addToCache(response, ['headernavigation'], 3600);
  return response
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = await useAsyncData("data-footernavigation", async () => {
  const { value, addToCache } = await useDataCache('data-footernavigation')

  if (value) {
    return value
  }

  const response = await loadFooterNavigationElements({ depth: 2 });
  // provide maxAge here
  addToCache(response, ['footernavigation'], 3600);
  return response;
});
provide("swNavigation-footer-navigation", footerData);

onMounted(() => {
  useGtagCookieConsent();
})
</script>

<template>
  <SharedLoadingIndicator />
  <LayoutNotifications />
  <LayoutPromotion />
<!--  <RenderCacheable :cache-tags="['headernavigation']" :max-age="3600">-->
  <LayoutHeader />
<!--  </RenderCacheable>-->
  <slot />
<!--  <RenderCacheable :cache-tags="['footernavigation']" :max-age="3600">-->
  <LayoutFooter />
<!--  </RenderCacheable>-->
</template>
